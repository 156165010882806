import type { IStructure, inputParameterDataType } from '@/interfaces'

export enum inputParameterEnum {
  lineDetail = 'lineDetail',
  structureType = 'structureType',
  spaceType = 'spaceType',
  styleType = 'styleType',
  materialTypeOne = 'materialTypeOne',
  materialTypeTwo = 'materialTypeTwo',
  lora = 'lora',
}

export type inputParameterWithoutLora = inputParameterEnum.spaceType | inputParameterEnum.styleType | inputParameterEnum.materialTypeOne | inputParameterEnum.materialTypeTwo

export const exteriorStructures: Record<inputParameterWithoutLora, IStructure> = {
  [inputParameterEnum.spaceType]: {
    title: '空間の用途',
    options: [
      {
        en: 'residential building',
        ja: '住宅',
      },
      {
        en: 'apartment building',
        ja: 'マンション',
      },
      {
        en: 'office building',
        ja: 'オフィスビル',
      },
      {
        en: 'shop',
        ja: 'ショップ',
      },
      {
        en: 'restaurant',
        ja: 'レストラン',
      },
      {
        en: 'hotel',
        ja: 'ホテル',
      },
      {
        en: 'commercial facility',
        ja: '商業施設',
      },
    ],
  },

  [inputParameterEnum.styleType]: {
    title: '外観のスタイル',
    options: [
      {
        en: 'modern style',
        ja: 'モダン',
        src: '/images/modern.webp',
      },
      {
        en: 'deconstructism style',
        ja: 'デコンストラクション',
        src: '/images/deconstructism.webp',
      },
      {
        en: 'minimal style',
        ja: 'ミニマル',
        src: '/images/minimal.webp',
      },
      {
        en: 'natural style',
        ja: 'ナチュラル',
        src: '/images/natural.webp',
      },
      {
        en: 'classic style',
        ja: 'クラシック',
        src: '/images/classic.webp',
      },
      {
        en: 'Japanese modern style',
        ja: '和モダン',
        src: '/images/japanese_modern.webp',
      },
      {
        en: 'western style',
        ja: '洋風',
        src: '/images/western.webp',
      },
      {
        en: 'Scandinavian style',
        ja: '北欧',
        src: '/images/scandinavian.webp',
      },
      {
        en: 'green architecture style',
        ja: 'グリーン建築',
        src: '/images/green_architecture.webp',
      },
      {
        en: 'futuristic style',
        ja: '未来的',
        src: '/images/futuristic.webp',
      },
      {
        en: 'monotone style',
        ja: 'モノトーン',
        src: '/images/monotone.webp',
      },
      {
        en: 'tiling style',
        ja: 'タイル貼り',
        src: '/images/tiling.webp',
      },
      {
        en: 'wall painting style',
        ja: '外壁塗装',
        src: '/images/wall_painting.webp',
      },
      {
        en: 'galvalume style',
        ja: 'ガルバリウムスタイル',
        src: '/images/exterior_galvalume style_ver2_kohya_step18000.webp',
      },
    ],
  },

  [inputParameterEnum.materialTypeOne]: {
    title: '外装材',
    options: [
      {
        en: 'glass curtain wall',
        ja: 'カーテンウォール',
      },
      {
        en: 'stone wall',
        ja: '石',
      },
      {
        en: 'marble wall',
        ja: '大理石',
      },
      {
        en: 'metal wall',
        ja: 'メタル',
      },
      {
        en: 'wood wall',
        ja: '木目調',
      },
      {
        en: 'brick wall',
        ja: 'レンガ',
      },
      {
        en: 'concrete wall',
        ja: 'コンクリート',
      },
      {
        en: 'black wall',
        ja: 'ブラック',
      },
      {
        en: 'white wall',
        ja: 'ホワイト',
      },
      {
        en: 'gray wall',
        ja: 'グレー',
      },
    ],
  },

  [inputParameterEnum.materialTypeTwo]: {
    title: '天気',
    options: [
      {
        en: 'sunny',
        ja: '晴れ',
      },
      {
        en: 'dusk',
        ja: '夕暮れ',
      },
      {
        en: 'night',
        ja: '夜',
      },
      {
        en: 'cloudy',
        ja: '曇り',
      },
      {
        en: 'foggy',
        ja: '霧',
      },
      {
        en: 'dawn',
        ja: '夜明け',
      },
      {
        en: 'rainy',
        ja: '雨',
      },
      {
        en: 'snowy',
        ja: '雪',
      },
    ],
  },
}

export const interiorStructures: Record<inputParameterWithoutLora, IStructure> = {
  [inputParameterEnum.spaceType]: {
    title: '空間の用途',
    options: [
      {
        en: 'living room',
        ja: 'リビングルーム',
      },
      {
        en: 'dining room',
        ja: 'ダイニングルーム',
      },
      {
        en: 'bed room',
        ja: 'ベッドルーム',
      },
      {
        en: 'office',
        ja: 'オフィス',
      },
      {
        en: 'shop',
        ja: 'ショップ',
      },
      {
        en: 'apparel shop',
        ja: 'アパレルショップ',
      },
      {
        en: 'restaurant',
        ja: 'レストラン',
      },
      {
        en: 'hotel lobby',
        ja: 'ホテルロビー',
      },
      {
        en: 'hotel rooms',
        ja: 'ホテル客室',
      },
      {
        en: 'salon',
        ja: 'サロン',
      },
      {
        en: 'commercial facility',
        ja: '商業施設',
      },
    ],
  },

  [inputParameterEnum.styleType]: {
    title: '内観のスタイル',
    options: [
      {
        en: 'modern style',
        ja: 'モダン',
        src: '/images/interior_modern.webp',
      },
      {
        en: 'natural style',
        ja: 'ナチュラル',
        src: '/images/interior_natural.webp',
      },
      {
        en: 'industrial style',
        ja: 'インダストリアル',
        src: '/images/interior_industrial.webp',
      },
      {
        en: 'monotone style',
        ja: 'モノトーン',
        src: '/images/interior_monotone.webp',
      },
      {
        en: 'minimal style',
        ja: 'ミニマル',
        src: '/images/interior_minimal.webp',
      },
      {
        en: 'cafe style',
        ja: 'カフェ',
        src: '/images/interior_cafe.webp',
      },
      {
        en: 'memphis style',
        ja: 'レトロポップ',
        src: '/images/interior_memphis.webp',
      },
      {
        en: 'futuristic style',
        ja: '未来風',
        src: '/images/interior_futuristic.webp',
      },
      {
        en: 'classic style',
        ja: 'クラシック',
        src: '/images/interior_classic.webp',
      },
      {
        en: 'indoor green style',
        ja: 'インドアグリーン',
        src: '/images/interior_indoor_green.webp',
      },
      {
        en: 'Japanese style',
        ja: '和風',
        src: '/images/interior_japanese.webp',
      },
      {
        en: 'emerald green oasis style',
        ja: 'エメラルドグリーン',
        src: '/images/Interior_Emerald_Green_Oasis_ver1.webp',
      },
      {
        en: 'silver metallic no kirameki style',
        ja: 'シルバーメタリックの煌めき',
        src: '/images/Interior_Silver Metallic no Kirameki_ver1.webp',
      },
      {
        en: 'pastel palette style',
        ja: 'パステルパレット',
        src: '/images/Interior_Pastel Palette_ver1.webp',
      },
      {
        en: 'brown no tuikyu style',
        ja: 'ブラウンの追求',
        src: '/images/Interior_Brown no Tuikyu_ver1.webp',
      },
      {
        en: 'white & neon style',
        ja: 'ホワイト＆ネオン',
        src: '/images/Interior_Commercial Facility_White & Neon_ver1.webp',
      },
      {
        en: 'plywood base',
        ja: 'プライウッドベース',
        src: '/images/Interior_Plywood Base_ver2_kohya_step18000.webp',
      },
      {
        en: 'grayish luxury',
        ja: 'グレイッシュラグジュアリー',
        src: '/images/Interior_Grayish Luxury_ver2_kohya_step18000.webp',
      },
      {
        en: 'honey shadow',
        ja: 'ハニーシャドウ',
        src: '/images/Interior_Honey Shadow_ver2_kohya_step18000.webp',
      },
      {
        en: 'beige rich gold',
        ja: 'ベージュリッチゴールド',
        src: '/images/Interior_Beige Rich Gold _ver2_kohya_step18000.webp',
      },
      {
        en: 'ocean breeze',
        ja: 'オーシャンブリーズ',
        src: '/images/Interior_Ocean Breeze_ver2_kohya_step18000.webp',
      },
      {
        en: 'shabby chic',
        ja: 'シャビーシック',
        src: '/images/Interior_Shabby Chic_ver2_kohya_step18000.webp',
      },
      {
        en: 'fresh vitamin',
        ja: 'フレッシュビタミン',
        src: '/images/interior_fresh vitamin_ver2_kohya_step18000.webp',
      },
      {
        en: 'teakwood modern',
        ja: 'チークウッドモダン',
        src: '/images/Interior_Teakwood modern_ver2_kohya_step18000.webp',
      },
      {
        en: 'moroccan blue',
        ja: 'モロッカンブルー',
        src: '/images/interior_moroccan blue_ver2_kohya_step18000.webp',
      },
      {
        en: 'casual feminine',
        ja: 'カジュアルフェミニン',
        src: '/images/interior_casual feminine_ver2_kohya_step18000.webp',
      },
      {
        en: 'dark modern',
        ja: 'ダークモダン',
        src: '/images/interior_dark modern_ver2_kohya_step18000.webp',
      },
      {
        en: 'bright natural',
        ja: 'ブライトナチュラル',
        src: '/images/interior_bright natural_ver2_kohya_step18000.webp',
      },
      {
        en: 'industrial copper',
        ja: 'インダストリアルカッパー',
        src: '/images/interior_industrial copper_ver2_kohya_step18000.webp',
      },
      {
        en: 'winter lodge',
        ja: 'ウィンターロッジ',
        src: '/images/interior_winter lodge_ver2_kohya_step18000.webp',
      },
      {
        en: 'white modern',
        ja: 'ホワイトモダン',
        src: '/images/interior_white modern_ver2_kohya_step17520.webp',
      },
      {
        en: 'earth color modern',
        ja: 'アースカラーモダン',
        src: '/images/interior_earth color modern_ver2_kohya_step18000.webp',
      },
      {
        en: 'japandi',
        ja: 'ジャパンディ',
        src: '/images/interior_japandi_ver2_kohya_step18000.webp',
      },
      {
        en: 'retro american diner',
        ja: 'レトロアメリカンダイナー',
        src: '/images/interior_retro american diner_ver2_kohya_step18000.webp',
      },
      {
        en: 'black & neon',
        ja: 'ブラック＆ネオン',
        src: '/images/interior_black & neon_ver2_kohya_step18200.webp',
      },
      {
        en: 'aqua blue fantasy',
        ja: 'アクアブルーファンタジー',
        src: '/images/interior_aqua blue fantasy_ver2_kohya_step18000.webp',
      },
      {
        en: 'violet harmony',
        ja: 'バイオレットハーモニー',
        src: '/images/interior_violet harmony_ver2_kohya_step18200.webp',
      },
      // {
      //   en: 'blue forest',
      //   ja: 'ブルーフォレスト',
      //   src: '/images/Interior_Blue Forest_ver2_kohya_step24000.webp',
      // },
    ],
  },

  [inputParameterEnum.materialTypeOne]: {
    title: '床材',
    options: [
      {
        en: 'wood flooring',
        ja: 'フローリング',
      },
      {
        en: 'marble floor',
        ja: '大理石',
      },
      {
        en: 'tile flooring',
        ja: 'タイル',
      },
      {
        en: 'carpet flooring',
        ja: 'カーペット',
      },
      {
        en: 'concrete flooring',
        ja: 'コンクリート',
      },
      {
        en: 'white flooring',
        ja: 'ホワイト',
      },
      {
        en: 'black flooring',
        ja: 'ブラック',
      },
      {
        en: 'gray flooring',
        ja: 'グレー',
      },
    ],
  },

  [inputParameterEnum.materialTypeTwo]: {
    title: '壁材',
    options: [
      {
        en: 'wood wall',
        ja: '木目調',
      },
      {
        en: 'wallpaper wall',
        ja: '壁紙',
      },
      {
        en: 'stone wall',
        ja: '石',
      },
      {
        en: 'tile wall',
        ja: 'タイル',
      },
      {
        en: 'white wall',
        ja: 'ホワイト',
      },
      {
        en: 'black wall',
        ja: 'ブラック',
      },
      {
        en: 'gray wall',
        ja: 'グレー',
      },
    ],
  },
}

export const freewordTag = 'フリーワード'

export enum freeWordArrayEnum {
  freeWordArray = 'freeWordArray',
  materialTypeOneFreeWordArray = 'materialTypeOneFreeWordArray',
  materialTypeTwoFreeWordArray = 'materialTypeTwoFreeWordArray',
}

export enum inputParameterTagEnum {
  style = 'style',
  color = 'color',
  material = 'material',
  finish = 'finish',
  sketch = 'sketch',
}

type inputParameterType = {
  [inputParameterTagEnum.style]: inputParameterDataType[]
  [inputParameterTagEnum.color]: inputParameterDataType[]
  [inputParameterTagEnum.material]?: inputParameterDataType[]
  [inputParameterTagEnum.finish]?: inputParameterDataType[]
  [inputParameterTagEnum.sketch]: inputParameterDataType[]
}

export const interiorInputParameters: inputParameterType = {
  [inputParameterTagEnum.style]: [
    {
      en: 'modern style',
      ja: 'モダン',
      src: '/images/interior_modern.webp',
    },
    {
      en: 'natural style',
      ja: 'ナチュラル',
      src: '/images/interior_natural.webp',
    },
    {
      en: 'industrial style',
      ja: 'インダストリアル',
      src: '/images/interior_industrial.webp',
    },
    {
      en: 'monotone style',
      ja: 'モノトーン',
      src: '/images/interior_monotone.webp',
    },
    {
      en: 'minimal style',
      ja: 'ミニマル',
      src: '/images/interior_minimal.webp',
    },
    {
      en: 'cafe style',
      ja: 'カフェ',
      src: '/images/interior_cafe.webp',
    },
    {
      en: 'memphis style',
      ja: 'レトロポップ',
      src: '/images/interior_memphis.webp',
    },
    {
      en: 'futuristic style',
      ja: '未来風',
      src: '/images/interior_futuristic.webp',
    },
    {
      en: 'classic style',
      ja: 'クラシック',
      src: '/images/interior_classic.webp',
    },
    {
      en: 'indoor green style',
      ja: 'インドアグリーン',
      src: '/images/interior_indoor_green.webp',
    },
    {
      en: 'Japanese style',
      ja: '和風',
      src: '/images/interior_japanese.webp',
    },
    {
      en: 'pastel palette style',
      ja: 'パステルパレット',
      src: '/images/Interior_Pastel Palette_ver1.webp',
    },
    {
      en: 'plywood base',
      ja: 'プライウッドベース',
      src: '/images/Interior_Plywood Base_ver2_kohya_step18000.webp',
    },
    {
      en: 'honey shadow',
      ja: 'ハニーシャドウ',
      src: '/images/Interior_Honey Shadow_ver2_kohya_step18000.webp',
    },
    {
      en: 'ocean breeze',
      ja: 'オーシャンブリーズ',
      src: '/images/Interior_Ocean Breeze_ver2_kohya_step18000.webp',
    },
    {
      en: 'shabby chic',
      ja: 'シャビーシック',
      src: '/images/Interior_Shabby Chic_ver2_kohya_step18000.webp',
    },
    {
      en: 'fresh vitamin',
      ja: 'フレッシュビタミン',
      src: '/images/interior_fresh vitamin_ver2_kohya_step18000.webp',
    },
    {
      en: 'teakwood modern',
      ja: 'チークウッドモダン',
      src: '/images/Interior_Teakwood modern_ver2_kohya_step18000.webp',
    },
    {
      en: 'casual feminine',
      ja: 'カジュアルフェミニン',
      src: '/images/interior_casual feminine_ver2_kohya_step18000.webp',
    },
    {
      en: 'dark modern',
      ja: 'ダークモダン',
      src: '/images/interior_dark modern_ver2_kohya_step18000.webp',
    },
    {
      en: 'bright natural',
      ja: 'ブライトナチュラル',
      src: '/images/interior_bright natural_ver2_kohya_step18000.webp',
    },
    {
      en: 'industrial copper',
      ja: 'インダストリアルカッパー',
      src: '/images/interior_industrial copper_ver2_kohya_step18000.webp',
    },
    {
      en: 'winter lodge',
      ja: 'ウィンターロッジ',
      src: '/images/interior_winter lodge_ver2_kohya_step18000.webp',
    },
    {
      en: 'white modern',
      ja: 'ホワイトモダン',
      src: '/images/interior_white modern_ver2_kohya_step17520.webp',
    },
    {
      en: 'earth color modern',
      ja: 'アースカラーモダン',
      src: '/images/interior_earth color modern_ver2_kohya_step18000.webp',
    },
    {
      en: 'japandi',
      ja: 'ジャパンディ',
      src: '/images/interior_japandi_ver2_kohya_step18000.webp',
    },
    {
      en: 'retro american diner',
      ja: 'レトロアメリカンダイナー',
      src: '/images/interior_retro american diner_ver2_kohya_step18000.webp',

    },
  ],
  [inputParameterTagEnum.color]: [
    {
      en: 'emerald green oasis style',
      ja: 'エメラルドグリーン',
      src: '/images/Interior_Emerald_Green_Oasis_ver1.webp',
    },
    {
      en: 'silver metallic no kirameki style',
      ja: 'シルバーメタリックの煌めき',
      src: '/images/Interior_Silver Metallic no Kirameki_ver1.webp',
    },
    {
      en: 'brown no tuikyu style',
      ja: 'ブラウンの追求',
      src: '/images/Interior_Brown no Tuikyu_ver1.webp',
    },
    {
      en: 'white & neon style',
      ja: 'ホワイト＆ネオン',
      src: '/images/Interior_Commercial Facility_White & Neon_ver1.webp',
    },
    {
      en: 'grayish luxury',
      ja: 'グレイッシュラグジュアリー',
      src: '/images/Interior_Grayish Luxury_ver2_kohya_step18000.webp',
    },
    {
      en: 'beige rich gold',
      ja: 'ベージュリッチゴールド',
      src: '/images/Interior_Beige Rich Gold _ver2_kohya_step18000.webp',
    },
    {
      en: 'moroccan blue',
      ja: 'モロッカンブルー',
      src: '/images/interior_moroccan blue_ver2_kohya_step18000.webp',
    },
    {
      en: 'black & neon',
      ja: 'ブラック＆ネオン',
      src: '/images/interior_black & neon_ver2_kohya_step18200.webp',
    },
    {
      en: 'aqua blue fantasy',
      ja: 'アクアブルーファンタジー',
      src: '/images/interior_aqua blue fantasy_ver2_kohya_step18000.webp',
    },
    {
      en: 'violet harmony',
      ja: 'バイオレットハーモニー',
      src: '/images/interior_violet harmony_ver2_kohya_step18200.webp',
    },
  ],
  [inputParameterTagEnum.material]: [
    {
      en: 'material flooring',
      ja: 'フローリング(ブラウン)',
      src: '/images/interior_material flooring_ver2_kohya_step18000.webp',
    },
    {
      en: 'material concrete gray',
      ja: 'コンクリート(グレー)',
      src: '/images/interior_material concrete gray_ver2_kohya_step18000.webp',
    },
    {
      en: 'material stone marble',
      ja: 'ストーン(ホワイトマーブル)',
      src: '/images/interior_material stone marble_ver2_kohya_step18000.webp',
    },

  ],
  [inputParameterTagEnum.sketch]: [
    {
      en: 'monochrome sketch modern style',
      ja: 'モノクロスケッチ：モダン',
      src: '/images/interior_monochrome_sketch_modern style_ver2_kohya_step18000.webp',
    },
    {
      en: 'watercolor sketch modern style',
      ja: '水彩スケッチ：モダン',
      src: '/images/interior_watercolor_sketch_modern style_ver2_kohya_step18000.webp',
    },
    {
      en: 'watercolor sketch natural style',
      ja: '水彩スケッチ：ナチュラル',
      src: '/images/interior_watercolor_sketch_natural style_ver2_kohya_step18000.webp',
    },
    {
      en: 'watercolor sketch industrial style',
      ja: '水彩スケッチ：インダストリアル',
      src: '/images/interior_watercolor_sketch_industrial style_ver2_kohya_step18000.webp',
    },
    {
      en: 'watercolor sketch monotone style',
      ja: '水彩スケッチ：モノトーン',
      src: '/images/interior_watercolor_sketch_monotone style_ver2_kohya_step18000.webp',
    },
  ],
}

export const exteriorInputParameters: inputParameterType = {
  [inputParameterTagEnum.style]: [
    {
      en: 'modern style',
      ja: 'モダン',
      src: '/images/modern.webp',
    },
    {
      en: 'deconstructism style',
      ja: 'デコンストラクション',
      src: '/images/deconstructism.webp',
    },
    {
      en: 'minimal style',
      ja: 'ミニマル',
      src: '/images/minimal.webp',
    },
    {
      en: 'natural style',
      ja: 'ナチュラル',
      src: '/images/natural.webp',
    },
    {
      en: 'classic style',
      ja: 'クラシック',
      src: '/images/classic.webp',
    },
    {
      en: 'Japanese modern style',
      ja: '和モダン',
      src: '/images/japanese_modern.webp',
    },
    {
      en: 'western style',
      ja: '洋風',
      src: '/images/western.webp',
    },
    {
      en: 'Scandinavian style',
      ja: '北欧',
      src: '/images/scandinavian.webp',
    },
    {
      en: 'green architecture style',
      ja: 'グリーン建築',
      src: '/images/green_architecture.webp',
    },
    {
      en: 'futuristic style',
      ja: '未来的',
      src: '/images/futuristic.webp',
    },
    {
      en: 'monotone style',
      ja: 'モノトーン',
      src: '/images/monotone.webp',
    },
  ],
  [inputParameterTagEnum.color]: [],
  [inputParameterTagEnum.finish]: [
    {
      en: 'tiling style',
      ja: 'タイル貼り',
      src: '/images/tiling.webp',
    },
    {
      en: 'wall painting style',
      ja: '外壁塗装',
      src: '/images/wall_painting.webp',
    },
    {
      en: 'galvalume style',
      ja: 'ガルバリウムスタイル',
      src: '/images/exterior_galvalume style_ver2_kohya_step18000.webp',
    },
  ],
  [inputParameterTagEnum.sketch]: [],
}

export enum styleInputParameterEnum {
  interior = 'interior',
  exterior = 'exterior',
}

export const styleInputParameters = {
  [styleInputParameterEnum.interior]: {
    title: 'インテリアスタイル',
    data: interiorInputParameters,
  },
  [styleInputParameterEnum.exterior]: {
    title: 'エクステリアスタイル',
    data: exteriorInputParameters,
  },
}
